import React from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'

import HomeButton from '../UI/Buttons/HomeButton'
import { BUTTON_COLOR, BUTTON_SIZE } from '../../support'
import { GatsbyImage } from 'gatsby-plugin-image'

const ThreePartsOfLoansItem = ({ item }) => {
  return (
    <Item>
      <Icon>
        <IconWrapper>
          <GatsbyImage
            image={item.icon?.gatsbyImageData}
            alt={item.icon.title}
          />
        </IconWrapper>
      </Icon>
      <div className="info">
        <Header
          dangerouslySetInnerHTML={{
            __html: item.header.childMarkdownRemark.html,
          }}
        />
        <Subheader
          dangerouslySetInnerHTML={{
            __html: item.content.childMarkdownRemark.html,
          }}
        />
      </div>
      <HomeButton
        height={BUTTON_SIZE.HEIGHT_S}
        padding={BUTTON_SIZE.PADDING_S}
        fontSize={BUTTON_SIZE.FONT_SIZE_S}
        background={BUTTON_COLOR.GRAY}
        backgroundHover={BUTTON_COLOR.GRAY_HOVER}
        color={BUTTON_COLOR.TEXT_COLOR_BLUE}
        text={item.button.text}
        handleClick={() => navigate(item.button.url)}
        margin="auto 0 0 0"
      />
    </Item>
  )
}

const Item = styled.div`
  background: var(--base-gray);
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 16px;
  .info {
    margin: 40px 0;
  }
  @media only screen and (max-width: 991px) {
    padding: 16px;
    .info {
      margin: 18px 0;
    }
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 20px 0;
  }
`
const Icon = styled.div`
  background: var(--base-navy);
  padding: 48px 0;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`
const IconWrapper = styled.div`
  margin: 0 auto;
`
const Header = styled.div`
  h3 {
    font-size: 24px;
  }
`
const Subheader = styled.div`
  p {
    font-size: 16px;
  }
`
export default ThreePartsOfLoansItem
