import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { FaTimes } from 'react-icons/fa'
import BasicCard from '../UI/Card/BasicCard'
import { BUTTON_COLOR, BUTTON_SIZE } from '../../support'
import HomeButton from '../UI/Buttons/HomeButton'
import addQuery from '../../utils/addQuery'

// Look for /personal-loan page to see the cta example

const FloatedCTA = ({ classNameToPop, children, button }) => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', popCTA)
      popCTA()
      return () => window.removeEventListener('scroll', popCTA)
    } else {
      return
    }
  })

  const [closed, setClosed] = useState(false)

  const popCTA = () => {
    if (typeof document !== 'undefined') {
      const hero = document
        .querySelector(`.${classNameToPop}`)
        ?.getBoundingClientRect()
      const heroBottom = hero?.bottom - 70
      const cta = document.querySelector('.cta').style
      const ctaWrapper = document.querySelector('.cta-wrapper').style
      if (heroBottom <= 0) {
        cta.transform =
          'translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)'
        cta.display = 'block'
        cta.transition = '.5s'
        ctaWrapper.padding = '0 30px 24px'
      } else {
        cta.transform =
          'translate3d(0px, 100%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)'
        cta.transition = '.5s'
        ctaWrapper.padding = '0 30px 0'
      }
    } else {
      return
    }
  }

  return (
    <CTAWrapper className="cta-wrapper">
      {closed ? (
        <Cta className="cta">
          <HomeButton
            height={BUTTON_SIZE.HEIGHT_L}
            padding={BUTTON_SIZE.PADDING_L}
            fontSize={BUTTON_SIZE.FONT_SIZE_L}
            background={BUTTON_COLOR.GREEN}
            backgroundHover={BUTTON_COLOR.GREEN_HOVER}
            color={BUTTON_COLOR.TEXT_COLOR_WHITE}
            margin="0 0 0 auto"
            text={`${button.text}`}
            handleClick={() => addQuery(button.link)}
          />
        </Cta>
      ) : (
        <Cta className="cta">
          <BasicCard
            background="var(--base-navy)"
            padding="36px 24px 36px 16px"
            margin="0 0 0 auto"
            maxWidth="513px"
            border="var(--base-navy)"
          >
            <button className="cta-close" onClick={() => setClosed(true)}>
              <FaTimes />
            </button>
            <div className="flex-row">
              <div className="cta-info">{children}</div>
              <HomeButton
                height={BUTTON_SIZE.HEIGHT_L}
                padding={BUTTON_SIZE.PADDING_L}
                fontSize={BUTTON_SIZE.FONT_SIZE_L}
                background={BUTTON_COLOR.GREEN}
                backgroundHover={BUTTON_COLOR.GREEN_HOVER}
                color={BUTTON_COLOR.TEXT_COLOR_WHITE}
                margin="auto"
                text={`${button.text}`}
                handleClick={() => addQuery(button.link)}
              />
            </div>
          </BasicCard>
        </Cta>
      )}
    </CTAWrapper>
  )
}

const CTAWrapper = styled.div`
  position: sticky;
  left: 0;
  top: auto;
  right: auto;
  bottom: 0;
  z-index: 12;
  padding: 0 30px 24px;
  flex-direction: column;
  align-items: center;
`
const Cta = styled.div`
  position: relative;
  width: 100%;
  max-width: 1232px;
  margin: 0 auto;
  font-family: QuicksandSemiBold;
  color: var(--white);
  transform: translate3d(0px, 100%, 0px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  display: none;
  .cta-close {
    color: var(--white);
    outline: none;
    background: transparent;
    &:active{
      outline: none;
      background: transparent;
    }
    position: absolute;
    padding: 8px;
    top: 8px;
    right: 8px;
    bottom: auto;
    left: auto;
    cursor: pointer;
  }
  .cta-info {
    max-width: 262px;
    margin-right: 24px;
    font-size: 16px;
  }
  h2 {
    margin-bottom: 8px;
    font-size: 24px;
    line-height: 1.2;
  }
  .flex-row {
    button {
      box-shadow: 0 6px 0 0 #19a35f;
    }
  }
  @media screen and (max-width: 479px) {
    .flex-row {
      flex-direction: column;
    }
    .cta-info {
      max-width: 100%;
      margin: 0;
    }
    button {
      margin: 16px auto 0 0
    }
  }
`

export default FloatedCTA
