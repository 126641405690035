import React from 'react'
import styled from 'styled-components'
import ContentContainer from '../UI/ContentContainer'

import ThreePartsOfLoansItem from './ThreePartsOfLoansItem'

const ThreePartsOfLoans = ({ iconTextAndButton }) => {
  return (
    <ContentContainer>
      <StepsForBiggerScreen>
        {iconTextAndButton.map((item, index) => (
          <ThreePartsOfLoansItem item={item} key={index} />
        ))}
      </StepsForBiggerScreen>
    </ContentContainer>
  )
}

const StepsForBiggerScreen = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 56px;
  grid-row-gap: 16px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  @media only screen and (max-width: 991px) {
    grid-column-gap: 16px;
  }
  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`

export default ThreePartsOfLoans
