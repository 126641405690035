import React from 'react'
import styled from 'styled-components'

import StepItemForBiggerScreen from './StepItemForBiggerScreen'
import StepItemForSmallerScreen from './StepItemForSmallerScreen'

const HowItWorksNewUi = ({howItWorks}) => {
  return (
    <Wrapper>
      <h2>How it works</h2>
      <Subheader>Get your personal loan in 3 easy steps</Subheader>
      <StepsForBiggerScreen>
        {howItWorks.map((item, index) => 
          <StepItemForBiggerScreen item={item} key={index}/>
        )}
      </StepsForBiggerScreen>
      <StepsForSmallerScreen>
        {howItWorks.map((item, index) => 
          <StepItemForSmallerScreen item={item} key={index}/>
        )}
      </StepsForSmallerScreen>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1232px;
  margin: 0 auto;
  background: rgb(237 250 243);
  padding: 64px;
  border-radius: 32px;
  // margin-bottom: 50px;
  @media screen and (max-width: 768px) {
    padding: 24px;
  }

`

const StepsForBiggerScreen = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @media screen and (max-width: 768px) {
    display: none;
  }
`
const StepsForSmallerScreen = styled.div`
  display: none;
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
`
const Subheader = styled.p`
  font-size: 20px;
  margin: 30px 0 50px 0;
`

export default HowItWorksNewUi