import React from 'react'

import DontJustTake from '../UI/DontJustTake';
import HowItWorksNewUi from './HowItWorksNewUi';
import ThreePartsOfLoans from './ThreePartsOfLoans';

const UnderTheManWithPhone = ({page}) => {
  switch (page.page) {
    case 'personal-loans':
    case 'personal-loans-a':
      return (
        <>
          <HowItWorksNewUi howItWorks={page.howItWorks} />
          <ThreePartsOfLoans iconTextAndButton={page.iconTextAndButton} />
          <DontJustTake background={"var(--base-gray)"} />
        </>
      )
    // case 'credit-score-societyone':
    //   return (
    //     <h1>credit-score-societyone</h1>
    //   )
  
    default:
      return (
        <></>
      )
  }
}

export default UnderTheManWithPhone