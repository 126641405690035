import React from 'react'
import styled from 'styled-components'

import CMSImage from '../UI/CMSImage'

const StepItemForBiggerScreen = ({item}) => {

  return (
    <ItemWrapper>
      <div className='defaultState'>
        <Img>
          <CMSImage
            svg={item.icon.svg}
            gatsbyImageData={item.icon?.gatsbyImageData}
            file={item.icon.file}
            alt={item.icon.title}
          />
        </Img>
        <ItemHeader 
          dangerouslySetInnerHTML={{
            __html: item.header.childMarkdownRemark.html
          }}
        />
      </div>
      <HoverState>
        <ItemSubheader 
          dangerouslySetInnerHTML={{
            __html: item.content.childMarkdownRemark.html
          }}
        />
      </HoverState>
    </ItemWrapper>
  )
}

const ItemWrapper = styled.div`
  background: white;
  padding: 24px;
  height: 134px;
  border-radius: 16px;
  cursor: pointer;
  width: 28%;
  position: relative;
  .defaultState {
    display: flex;
    flex-direction: column;
    align-items: center;  
  };
  &:hover {
    .defaultState {
      display: none;
    }
  }
`
const Img = styled.div`
  width: 32px;
  margin-bottom: 10px;
`

const HoverState = styled.div`
  position: absolute;
  top: 0;
  left: 10px;
  opacity: 0.0;
  &:hover {
    opacity: 1.0;
  }
`
const ItemHeader = styled.div`
  h3 {
    font-size: 24px;
    text-align: center;
    @media screen and (max-width: 991px) {
      font-size: 21px;
    }

  }
`
const ItemSubheader = styled.div`
  text-align: center;
  @media screen and (max-width: 991px) {
    p {
      font-size: 16px;
    }  
  }
`
export default StepItemForBiggerScreen