import React from 'react'
import { graphql } from 'gatsby'
import loadable from '@loadable/component'

import Seo from '../components/seo'
import Layout from '../layouts/layout'
// import { parseJson } from '../support'
import HeaderWithImageWithBackground from '../components/ForNewUi/HeaderWithImageWithBackground'
import AwardListInHeader from '../components/blocks/AwardListInHeader'
import FaqsWithButtons from '../components/UI/FaqsWithButtons'
import ContentContainer from '../components/UI/ContentContainer'
import QuestionsAccordionForNewUi from '../components/ForNewUi/QuestionsAccordionForNewUi'
import UnderTheManWithPhone from '../components/ForNewUi/UnderTheManWithPhone'
import ImageWithFaqs from '../components/ForNewUi/ImageWithFaqs'
import FloatedCTA from '../components/ForNewUi/FloatedCTA'
import { getArticleMarkupForPageNewUi } from '../support/structuredDataConfig/seoS1'
import SeoContent from '../components/ForNewUi/SeoContent'
import { aggregateRating } from '../support/structuredDataConfig/dontJustTakeReview'
import AreatenSnippet from '../components/blocks/AreatenSnippet'

const Carouselv2 = loadable(() => import('../components/ForNewUi/PersonalLoanCarousel/Carouselv2')) 
const S1App = loadable(() => import('../components/UI/S1App')) 

const NewUIPagesTemplate = ({ data, path }) => {

  const page = data.contentfulPageNewUi
  const faqs = page.faqs
  const faqsStructuredData = faqs.map(faq => {
    return {
      "name": faq.question,
      "@type": "Question",
      "acceptedAnswer": {
        "text": faq.answer.answer,
        "@type": "Answer"
      }
    }
  })
  const structuredData = {
    "@context": "https://schema.org",
    "itemReviewed": {
      "@type": "Product",
      "name": "PersonalLoans",
      "aggregateRating": aggregateRating,
      "review": {
        "@type": "Review",
        "reviewRating": {
            "@type": "Rating",
            "ratingValue": "4.7"
        },
        "author": {
          "@type": "Organization",
          "name": "Google"
        }
      },
    },
    "faq": {
      "@type": "FAQPage",
      "mainEntity": faqsStructuredData
    },
  }
  // const escapedStructuredData = page.seoStructuredData?.internal?.content
  // const structuredData = parseJson(escapedStructuredData)
  // structuredData.review = reviewRating
  structuredData.article = getArticleMarkupForPageNewUi(page)

  
  return (
    <AreatenSnippet slug={page.slug} isProgPages={false}>
      <Layout hasGetYourRate={false}>
        <Seo title={page.seoMetaTitle} description={page.seoMetaDescription} image={page.seoMetaImage?.fluid?.src} path={path} structuredData={structuredData} />
        <HeaderWithImageWithBackground page={page} />
        <AwardListInHeader>
          <p><strong className="quicksand">56,000+</strong> people trust SocietyOne</p>
        </AwardListInHeader>

        {page.cardsType === 'Carousel' && 
          <Carouselv2 page={page} />
        }

        <ImageWithFaqs page={page} />
        <UnderTheManWithPhone page={page} />
        <SeoContent seoContent={page.seoContent} />
        <ContentContainer padding="120px 0 80px">
          <FaqsWithButtons subHeader={page.faqsSubheader} page={page} faqsLength={33} width={'1232px'}>
            <QuestionsAccordionForNewUi />
          </FaqsWithButtons>
          {/* {page.hasApp && <S1App
            header={'Manage your loan on the move'} 
            subHeader={'View your balance, make extra payments and more, with the SocietyOne app'}
            color={'white'}
            wrapperWidth={'1232px'}
            background={'linear-gradient(to right, rgb(38 75 101), rgb(74 146 196))'}
          ></S1App>} */}
        </ContentContainer>
        <FloatedCTA classNameToPop="new-ui-hero" button={{link: "https://quote.societyone.com.au/quote/step-1/", text: "Get My Rate"}}>
          <h2>Ready to get started?</h2>
          <div>Getting a quote won't affect your credit score.</div>
        </FloatedCTA>
      </Layout>
    </AreatenSnippet>
  )
}

export default NewUIPagesTemplate

export const pageQuery = graphql`
  query PageNewUIBySlug(
    $slug: String!
  ) {
    contentfulPageNewUi(slug: {eq: $slug}) {
      page
      slug
      createdAt
      seoContent {
        childMarkdownRemark {
          html
        }
      }
      header {
        childMarkdownRemark {
          html
        }
      }
      subheader {
        childMarkdownRemark {
          html
        }
      }
      headerImage {
        gatsbyImageData(
          placeholder:NONE
          formats: [PNG]
        )
        title
      }
      headerButton {
        text
        url
      }
      headerQuestion {
        text
        url
        iconOnLeft {
          gatsbyImageData
          svg {
            content
          }
          file {
            url
            contentType
            details {
              image {
                height
                width
              }
            }
          }
          title
        }
      }
      headerBackgroundColorEnd
      headerBackgroundColorStart
      cardsHeader
      cardsSubheader
      cardsContent {
        title
        description {
          childMarkdownRemark {
            html
          }
        }
        buttonText
        linkOnClick
        image {
          title
          gatsbyImageData(placeholder:NONE)
        }
      }
      cardsType
      imageWithFaQsHeader
      imageWithFaQsImage {
        gatsbyImageData(placeholder:NONE)
        title
      }
      imageWithFaqsFaqs {
        question
        answer {
          childMarkdownRemark {
            html
          }
        }
        button {
          text
          url
        }
        iconOnLeft {
          gatsbyImageData
          svg {
            content
          }
          file {
            url
            contentType
            details {
              image {
                height
                width
              }
            }
          }
          title
        }
      }
      howItWorks {
        header {
          childMarkdownRemark {
            html
          }
        }
        icon {
          gatsbyImageData(placeholder:NONE)
          title
          file {
            url
            contentType
            details {
              image {
                height
                width
              }
            }
          }
          internal {
            mediaType
          }
        }
        content {
          childMarkdownRemark {
            html
          }
        }
      }
      iconTextAndButton {
        header {
          childMarkdownRemark {
            html
          }
        }
        icon {
          gatsbyImageData(placeholder:NONE)
          title
        }
        content {
          childMarkdownRemark {
            html
          }
        }
        button {
          text
          url
        }
      }
      faqsSubheader
      faqs {
        question
        answer {
          answer
          childMarkdownRemark {
            html
          }
        }
      }
      seoMetaTitle
      seoMetaDescription
      seoMetaImage {
        fluid {
          src
        }
      }
      seoStructuredData {
        internal {
          content
        }
      }
    }
  }
`

