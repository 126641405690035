import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { navigate } from 'gatsby'

import ContentContainer from '../UI/ContentContainer'
import HomeButton from '../UI/Buttons/HomeButton'
import { BUTTON_COLOR, BUTTON_SIZE } from '../../support'
import CMSImage from '../UI/CMSImage'

const ImageWithFaqs = ({page}) => {
  return (
    <ContentContainer>
      <Wrapper>
        <h2>{page.imageWithFaQsHeader}</h2>
        <ContentWrapper>
          <Image>
            <GatsbyImage image={page.imageWithFaQsImage?.gatsbyImageData} alt={page.imageWithFaQsImage.title}/>
          </Image>
          <div>
            {page.imageWithFaqsFaqs && page.imageWithFaqsFaqs.map((item, index) =>
              <Accordion disableGutters style={{ boxShadow: 'none', borderBottom: '1px solid rgb(235 235 235)' }} key={index}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  style={{ padding: '0' }}
                >
                  <Header>
                    {
                      item.iconOnLeft &&
                      <div className="icon-wrapper">
                        <CMSImage
                          gatsbyImageData={item.iconOnLeft?.gatsbyImageData}
                          file={item.iconOnLeft.file}
                          alt={item.iconOnLeft.title}
                        />
                      </div>
                    }
                    {item.question}
                  </Header>
                </AccordionSummary>
                <AccordionDetails style={{ padding: '0' }}>
                  <Content dangerouslySetInnerHTML={{ __html: item.answer.childMarkdownRemark.html }} />
                  {item.button && <HomeButton
                    height={BUTTON_SIZE.HEIGHT_S}
                    padding={BUTTON_SIZE.PADDING_S}
                    fontSize={BUTTON_SIZE.FONT_SIZE_S}
                    background={BUTTON_COLOR.GRAY}
                    backgroundHover={BUTTON_COLOR.GRAY_HOVER}
                    color={BUTTON_COLOR.TEXT_COLOR_BLUE}
                    text={ item.button.text }
                    handleClick={() => navigate(item.button.url)}
                    margin={'15px 0'}
                  />}

                </AccordionDetails>
              </Accordion>
            )}
          </div>
        </ContentWrapper>

      </Wrapper>
    
    </ContentContainer>
  )
}

const Wrapper = styled.div`
  @media screen and (max-width: 991px) {
    h2 {
      margin-bottom: 40px;
    }
  };
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
`
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin: 0 auto;
  .css-1wc0u5o-MuiPaper-root-MuiAccordion-root:before {
    background-color: white;
  }
  @media screen and (max-width: 991px) {
    flex-direction: column-reverse;
  }

`
const Image = styled.div`
  max-width: 576px;
`
const Header = styled.h3`
  display: flex;
  align-items: center;
  color: rgb(38 75 101);
	padding: 0; 
	margin: 10px 0;  
  font-size: 24px;
  .icon-wrapper {
    flex-shrink: 0;
    width: 32px;
    height: auto;
    margin-right: 12px;
    svg {
      width: 100%;
      height: 100%;
    }
  }
  @media screen and (max-width: 991px) {
    font-size: 20px;
  }
`

const Content = styled.div`
  color: rgb(38 75 101);
	font-size: 16px; 
	max-width: 1020px;
	margin: 0;
	@media screen and (max-width: 991px) {
		max-width: 100%;
	};

	p {
		font-size: 18px !important; 
		text-align: left !important;
		@media screen and (max-width: 991px) {
			width: 100% !important; 
		};
	}
`
export default ImageWithFaqs