import React from 'react'
import styled from 'styled-components'

import ContentContainer from '../UI/ContentContainer'

const SeoContent = ({seoContent}) => {
	return (
		<ContentContainer>
			<Wrapper>
			<div dangerouslySetInnerHTML={{
						__html: seoContent?.childMarkdownRemark.html,
					}}/>
			</Wrapper>
		</ContentContainer>
	)
}

const Wrapper = styled.div`
	p {
		font-size: 20px;

		margin: 30px auto;
		a {
			text-decoration: underline;	
		}
		@media screen and (max-width: 768px) {
			font-size: 18px;
		};
		@media screen and (max-width: 480px) {
			max-width: 100%;
		};

	};
`

export default SeoContent


