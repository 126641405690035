import React from 'react'
import styled from 'styled-components'
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import CMSImage from '../UI/CMSImage'

const StepItemForSmallerScreen = ({item}) => {

  return (
    <ItemWrapper>
      <Accordion disableGutters style={{ boxShadow: 'none', borderBottom: 'none' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          style={{ padding: '0', margin: '0 30px' }}
        >
          <DefaultState>
            <Img>
              <CMSImage
                svg={item.icon.svg}
                gatsbyImageData={item.icon?.gatsbyImageData}
                file={item.icon.file}
                alt={item.icon.title}
              />
            </Img>
            <ItemHeader 
              dangerouslySetInnerHTML={{
                __html: item.header.childMarkdownRemark.html
              }}
            />
          </DefaultState>
        </AccordionSummary>
        <AccordionDetails style={{ padding: '0', margin: '0 30px' }}>
          <ItemSubheader 
            dangerouslySetInnerHTML={{
              __html: item.content.childMarkdownRemark.html
            }}
          />
        </AccordionDetails>
      </Accordion>
    </ItemWrapper>
  )
}

const ItemWrapper = styled.div`
  background: white;
  padding: 15px 0;
  border-radius: 16px;
  cursor: pointer;
  width: 100%;
  margin-bottom: 20px;
`
const DefaultState = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;  
  justify-content: flex-start;
`
const Img = styled.div`
  width: 40px;
  margin: 0 10px 0px 0;
  flex-shrink: 0;
  @media screen and (max-width: 479px) {
    width: 32px;
    margin-right: 8px;
  }
`

const ItemHeader = styled.div`
  h3 {
    overflow-wrap: break-word;
    font-size: 24px;
    text-align: center;
    @media screen and (max-width: 991px) {
      font-size: 21px;
      text-align: left;
    }
  }
`
const ItemSubheader = styled.div`
  text-align: center;
  @media screen and (max-width: 991px) {
    p {
      font-size: 16px;
    }  
  }
`
export default StepItemForSmallerScreen