import React, { useContext } from 'react'
import { Grid } from '@mui/material'

import QuestionsAccordionItemForNewUi from './QuestionsAccordionItemForNewUi'
import { FaqsWithButtonsContext } from '../UI/FaqsWithButtons'

const QuestionsAccordionForNewUi = () => {

	const faqsArray = useContext(FaqsWithButtonsContext)
	const firstThreeFaqs = faqsArray.slice(0, 3)
	const firstColFaqs = faqsArray.slice(3, Math.ceil((faqsArray.length + 3) / 2))
	const secondColFaqs = faqsArray.slice(Math.ceil((faqsArray.length + 3) / 2))
	return (
		<Grid container spacing={2}>
			<Grid item xs={12} >	
				{faqsArray && firstThreeFaqs.map((item, index) =>
					<QuestionsAccordionItemForNewUi item={item} index={index} isExpanded={true}/>
				)}
			</Grid>
			<Grid item xs={12} md={6} >	
				{faqsArray && firstColFaqs.map((item, index) =>
					<QuestionsAccordionItemForNewUi item={item} index={index}/>
				)}
			</Grid>
			<Grid item xs={12} md={6} >
				{faqsArray && secondColFaqs.map((item, index) =>
					<QuestionsAccordionItemForNewUi item={item} index={index}/>
				)}
			</Grid>
		</Grid>
	)
}

export default QuestionsAccordionForNewUi
